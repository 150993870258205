.tile {
    width: 100px;
    height: 100px;
    font-size: 40px;
    background: yellowgreen;
    border:8px solid grey;
    display: inline-block;
    float:left;
    text-align: center;
    line-height: 0px;

}