.App {
  text-align: center;
}
 
 .container {
   width: 350px;
   margin-left: auto;
   margin-right: auto;
   margin-top: 0px;
 }
 
 .resetButton{
   background-color: brown;
   width: 80px;
   height: 25px;
   color: antiquewhite;
   border: none;
   margin: 8px auto 8px auto;
 }