body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}
 
 .container {
   width: 350px;
   margin-left: auto;
   margin-right: auto;
   margin-top: 0px;
 }
 
 .resetButton{
   background-color: brown;
   width: 80px;
   height: 25px;
   color: antiquewhite;
   border: none;
   margin: 8px auto 8px auto;
 }
.hidden{
    display: none;
}
.visible{
    display: block;
}
.tile {
    width: 100px;
    height: 100px;
    font-size: 40px;
    background: yellowgreen;
    border:8px solid grey;
    display: inline-block;
    float:left;
    text-align: center;
    line-height: 0px;

}
